import React, { Component } from 'react';
import { Col, Container, Row, Button, Form, FormGroup, FormLabel } from 'react-bootstrap';
import LoadingBar from '../../common/LoadingBar';
import Endpoints from '../../common/Endpoints';
import { GET, extractData, POST } from '../../../Consumer';
import { COMPANIES, SERVICE_TIMES, SERVICE_TIMES_ORDERED, PREFERENCES_SUB_NAV_LIST, REGIONS } from '../../common/constants';
import Header from '../../common/Header';
import { SuccessAlert, ErrorAlert, WarningAlert } from '../../common/Alert';
import './Region.scss'

const SUCCESS_MESSAGE = 'Your courier preferences have been updated successfully.';
const ERROR_MESSAGE = 'There was a problem updating your courier preferences. Please try again.';
const EU_SFP_MESSAGE = 'We can arrange shipping manually for EU orders on your behalf. ';
const ROW_SFP_MESSAGE = 'We can arrange shipping manually for Rest of World orders on your behalf. ';
const SFP_MESSAGE = 'This must be done by calling us on 028 3950 7860 or emailing support@selazar.com once your order has been submitted. Please note this may add time to orders being shipped and you must use self fulfillment exclusively. You can arrange self fulfillment by contacting us on 028 3950 7860 or emailing support@selazar.com.';
const SFP = 'Self Fulfilled Package';
const UPS_WARNING_TITLE = "Warning";
const UPS_WARNING = "Please note, costs for this expedited international service can be higher than standard";

class Region extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      serviceTimeOptions: [],
      currentPreferences: [],
      expediteROWCompanyIDs: [],
      showError: false,
      showSuccess: false
    };
  }

  async componentDidMount() {
    await Promise.all([
      this.fetchServiceTimes(),
      this.fetchCurrentPreferences(),
    ]);
    this.setEmptyPreferences();
    this.setState({ loading: false });
  }

  async fetchServiceTimes() {
    return await GET(Endpoints.RETAILER.GET.SERVICES_TIMES_OPTIONS)
      .then(response => {
        if (response.ok) return response.json();
      })
      .then(results => {
        this.setState({ serviceTimeOptions: extractData(results) });
      })
      .catch(error => console.log(error));
  }

  async fetchCurrentPreferences() {
    return await GET(Endpoints.RETAILER.GET.COURIER_REGIONAL_PREFERENCES)
      .then(response => {
        if (response.ok) return response.json();
      })
      .then(results => {
        const currentPreferences = Array.isArray(extractData(results)) ? extractData(results) : [];
        const expediteROWCompanyIDs = this.getExpediteROWCompanyIds(currentPreferences)
        this.setState({ currentPreferences: currentPreferences, expediteROWCompanyIDs: expediteROWCompanyIDs });
      })
      .catch(error => console.log(error));
  }

  // If retailer has no current preferences populate with the service time options
  setEmptyPreferences() {
    const { serviceTimeOptions, currentPreferences } = this.state;
    if (currentPreferences.length === 0 && serviceTimeOptions && serviceTimeOptions.length) {
      const defaultPreferences = [];
      const currentCompanyID = JSON.parse(localStorage.getItem('user')).companyID;

      for (const regionPreferences of serviceTimeOptions) {
        const countryRegion = regionPreferences.countryRegion;
        const preferences = [];
        for (const preference of regionPreferences.availableServiceTimeOptions.filter(s => s.companyName !== SFP)) {
          preferences.push({ partnerCompanyID: preference.companyID, ServiceTimes: [] });
        }
        defaultPreferences.push({ companyID: currentCompanyID, countryRegion: countryRegion, preferences: preferences });
      }
      const expediteROWCompanyIDs = this.getExpediteROWCompanyIds(defaultPreferences)
      this.setState({ currentPreferences: defaultPreferences, expediteROWCompanyIDs: expediteROWCompanyIDs });
    }
  }

  toggleServiceTime(companyID, countryRegion, serviceTime) {
    const { currentPreferences } = this.state;

    const region = currentPreferences.find(t => t.countryRegion === countryRegion);
    const preference = region ? region.preferences.find(p => p.partnerCompanyID === companyID) : false;
    const isExisting = preference ? preference.ServiceTimes.find(s => s === serviceTime) : false;

    const newPreferencesState = currentPreferences.map(cp => {

      if (cp.countryRegion === countryRegion) {

        const newPreferencesState = cp.preferences.map(p => {
          if (p.partnerCompanyID === companyID) {
            let newTrackingPreference = { ...p.serviceTrackingPreferences };
            
            if (!isExisting) newTrackingPreference = { ...p.serviceTrackingPreferences, [serviceTime]: !isExisting };
            else newTrackingPreference = { ...p.serviceTrackingPreferences, [serviceTime]: false };

            let newServiceTimesPreference = [];
            isExisting ? newServiceTimesPreference = p.ServiceTimes.filter(st => st !== serviceTime) : newServiceTimesPreference = [ ...p.ServiceTimes, serviceTime];
            return { ...p, ServiceTimes: newServiceTimesPreference, serviceTrackingPreferences: newTrackingPreference };
          }
          
          return p;
        });

        return { ...cp, preferences: newPreferencesState };
      }
      
      return cp;
    });

    const expediteROWCompanyIDs = this.getExpediteROWCompanyIds(currentPreferences);
    this.setState({ currentPreferences: newPreferencesState, expediteROWCompanyIDs: expediteROWCompanyIDs });
  }

  checkCurrentPreferences(companyID, countryRegion, serviceTime) {
    const { currentPreferences } = this.state;
    const preferencesByRegion = currentPreferences.find(t => t.countryRegion === countryRegion);
    const preference = preferencesByRegion ? preferencesByRegion.preferences.find(p => p.partnerCompanyID === companyID) : false;
    const isExisting = preference ? preference.ServiceTimes.find(s => s === serviceTime) : false;

    return isExisting;
  }

  
  checkCurrentTrackingPreferences(companyID, countryRegion, serviceTime) {
    const { currentPreferences } = this.state;
    const preferencesByRegion = currentPreferences.find(t => t.countryRegion === countryRegion);
    const preference = preferencesByRegion ? preferencesByRegion.preferences.find(p => p.partnerCompanyID === companyID) : false;
    const isExisting = preference && preference.serviceTrackingPreferences?.hasOwnProperty(serviceTime) ? preference.serviceTrackingPreferences[serviceTime] : false;

    return isExisting;
  }

  onSubmit = (event) => {
    event.preventDefault();
    this.setState({ loading: true });
    const { currentPreferences } = this.state;

    POST(Endpoints.RETAILER.POST.COURIER_REGIONAL_PREFERENCES, currentPreferences)
      .then(response => {
        if (response && response.ok) return response.json();
      })
      .then(results => {
        const currentPreferences = Array.isArray(extractData(results)) ? extractData(results) : [];
        const expediteROWCompanyIDs = this.getExpediteROWCompanyIds(currentPreferences);
        this.setState({ currentPreferences: currentPreferences, expediteROWCompanyIDs: expediteROWCompanyIDs, loading: false, showError: results.error, showSuccess: !results.error });
      })
      .catch(error => console.log(error));
  }

  handleSfpChange(serviceTimeOption) {
    const { currentPreferences } = this.state;
    const currentCompanyID = JSON.parse(localStorage.getItem('user')).companyID;

    const region = currentPreferences.find(t => t.countryRegion === serviceTimeOption.countryRegion);
    const companyOption = serviceTimeOption.availableServiceTimeOptions.find(s => s.companyName === SFP);
    const preference = region ? region.preferences.find(p => p.partnerCompanyID === companyOption.companyID) : null;

    const updatedPreferences = currentPreferences.filter(t => t.countryRegion !== serviceTimeOption.countryRegion);

    if (!preference) updatedPreferences.push({ companyID: companyOption.companyID, countryRegion: serviceTimeOption.countryRegion, preferences: [{ ServiceTimes: Object.keys(companyOption.serviceTimes), partnerCompanyID: companyOption.companyID }] });
    else {
      const countryRegion = serviceTimeOption.countryRegion;
      const preferences = [];
      for (const preference of serviceTimeOption.availableServiceTimeOptions.filter(s => s.companyName !== SFP)) {
        preferences.push({ partnerCompanyID: preference.companyID, ServiceTimes: Object.keys(preference.serviceTimes) });
      }
      updatedPreferences.push({ companyID: currentCompanyID, countryRegion: countryRegion, preferences: preferences });

    }

    this.setState({ currentPreferences: updatedPreferences });
  }

  getExpediteROWCompanyIds = (defaultPreferences) => defaultPreferences.length > 0 ? defaultPreferences.find(m => m.countryRegion === REGIONS.RestOfWorld.code).preferences.filter(pref => pref.ServiceTimes.some(i => i === SERVICE_TIMES.OneToTwo || i === SERVICE_TIMES.TwoToThree)).map(a => a.partnerCompanyID) : [];

  checkSfpPreferences(serviceTimeOption) {
    const { currentPreferences, serviceTimeOptions } = this.state;
    if (serviceTimeOption.countryRegion === REGIONS.UK.code) return false;

    const preferencesByRegion = currentPreferences.find(t => t.countryRegion === serviceTimeOption.countryRegion);
    const companyOption = serviceTimeOptions.find(t => t.countryRegion === serviceTimeOption.countryRegion).availableServiceTimeOptions.find(s => s.companyName === SFP);
    const preference = preferencesByRegion ? preferencesByRegion.preferences.find(p => p.partnerCompanyID === companyOption.companyID) : null;
    return preference ? true : false;
  }

  sfpOption = (serviceTimeOption) => {
    if (serviceTimeOption.countryRegionName !== REGIONS.UK.code) {
      const message = serviceTimeOption.countryRegionName === REGIONS.EU.code ? `${EU_SFP_MESSAGE} ${SFP_MESSAGE}` : `${ROW_SFP_MESSAGE} ${SFP_MESSAGE}`;
      return (
        <FormGroup>
          <p><strong>Self Fulfill Shipping</strong></p>
          <div className="form-input-description mb-3">
            <p>{message}</p>
          </div>
          <label className="switch">
            <input id="sfpOption" type="checkbox" onChange={() => this.handleSfpChange(serviceTimeOption)} checked={this.checkSfpPreferences(serviceTimeOption)} />
            <span className="slider round"><span className="toggle-text">{this.checkSfpPreferences(serviceTimeOption) ? "Enabled" : "Disabled"}</span></span>
          </label>
        </FormGroup>
      );
    }
  }

  sortServiceTimes = (serviceTimes) => {
    serviceTimes.sort(function (a, b) {
      return (SERVICE_TIMES_ORDERED.indexOf(a) > SERVICE_TIMES_ORDERED.indexOf(b)) ? 1 : -1;
    });
    return serviceTimes;
  }

  handleToggleTrackingPreference = (companyID, countryRegion, serviceTime) => {
    const { currentPreferences } = this.state;

    const newPreferencesState = currentPreferences.map(cp => {

      if (cp.countryRegion === countryRegion) {

        const newPreferencesState = cp.preferences.map(p => {
          if (p.partnerCompanyID === companyID) {
            if (p.ServiceTimes.includes(serviceTime)) {
              const newTrackingPreference = { ...p.serviceTrackingPreferences, [serviceTime]: !p.serviceTrackingPreferences[serviceTime] };
              return { ...p, serviceTrackingPreferences: newTrackingPreference };
            }
          } 
          
          return p;
        });

        return { ...cp, preferences: newPreferencesState };
      }
      
      return cp;
    });

    this.setState({ currentPreferences: newPreferencesState });
  }

  buildForm() {
    const { serviceTimeOptions, expediteROWCompanyIDs } = this.state;
    const ups = serviceTimeOptions?.find(m => m.countryRegion === REGIONS.RestOfWorld.code)?.availableServiceTimeOptions.find(a => a.companyName === COMPANIES.UPS.name);
    const showUPSWarning = ups ? expediteROWCompanyIDs.findIndex(i => i === ups.companyID) > -1 : false;

    return (
      <Form onSubmit={this.onSubmit}>
        {serviceTimeOptions.map((st, wi) => {
          return (
            <React.Fragment key={`world-area-${wi}`}>
              <h5>{st.countryRegionName}</h5>
              {this.sfpOption(st)}
              <FormGroup>
              {st.availableServiceTimeOptions.filter(s => s.companyName !== SFP).sort((a, b) => a.companyName > b.companyName ? 1 : -1).map((a, sti) => {
                  return (
                    <React.Fragment key={`courier-${wi}-${sti}`}>
                      <p><strong>{a.companyName}</strong></p>
                      {(showUPSWarning && a.companyName === COMPANIES.UPS.name && st.countryRegionName === REGIONS.RestOfWorld.value) && <WarningAlert warningTitle={UPS_WARNING_TITLE} warningMessage={UPS_WARNING} />}
                      {this.sortServiceTimes(Object.keys(a.serviceTimes)).map((s, si) => {
                        return (
                          <FormGroup className="custom-control custom-checkbox" key={`check-${st.countryRegion}-${a.companyID}-${si}`}>
                            <input id={`input-${st.countryRegion}-${a.companyID}-${si}`} disabled={this.checkSfpPreferences(st)} className="custom-control-input" type="checkbox" onChange={() => this.toggleServiceTime(a.companyID, st.countryRegion, s)} checked={this.checkCurrentPreferences(a.companyID, st.countryRegion, s)} />
                            <FormLabel className="custom-control-label" htmlFor={`input-${st.countryRegion}-${a.companyID}-${si}`}>{a.serviceTimes[s]}</FormLabel>
                            {a.companyName.toUpperCase() === COMPANIES.ROYAL_MAIL.name.toUpperCase() &&
                              <label className="track-switch ml-5">
                                <input id="track-option" type="checkbox" onChange={() => this.handleToggleTrackingPreference(a.companyID, st.countryRegion, s)} checked={this.checkCurrentTrackingPreferences(a.companyID, st.countryRegion, s)} />
                                <span className="track-slider round"><span className="toggle-text">Tracked service</span></span>
                              </label>
                            }
                          </FormGroup>
                        );
                      })}
                    </React.Fragment>
                  )
                })}
              </FormGroup>
            </React.Fragment>
          )
        })}
        <Button variant="primary" className="float-right mt-3" type='submit'>Save</Button>
      </Form>
    );
  }

  render() {
    const { loading, showError, showSuccess, serviceTimeOptions } = this.state;
    return loading ? (<LoadingBar />) : (
      <React.Fragment>
        <Header title="Preferences" subNavList={PREFERENCES_SUB_NAV_LIST} activeKey="Regional" />
        <Container fluid>
          <Row>
            <Col sm={12} md={6}>
              <h5 className="mb-3">Regional Courier Preferences</h5>
              {showError && <ErrorAlert errorMessage={ERROR_MESSAGE} />}
              {showSuccess && <SuccessAlert successMessage={SUCCESS_MESSAGE} />}
              <p>Be aware that modifying these settings can result in orders failing to be processed and can limit the destinations you can deliver to.</p>
              <p>This can result in orders failing to be processed, in this situation, you will be notified. </p>
              <p><strong>If a courier is eligible for a selected service time but is not available for the order, the next eligible service time may be selected.</strong></p>
              <div className="form-input-description mb-3">
                <p> These options will override any default settings that the Selazar platform provides.</p>
              </div>
              {serviceTimeOptions
                ? this.buildForm()
                : <ErrorAlert errorMessage="Your warehouse selection does not have any available courier services." />
              }
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default Region;