import React, { Component } from 'react';
import { Container, Col, Row, Button, Form, FormGroup, FormLabel } from 'react-bootstrap';
import LoadingBar from '../../common/LoadingBar';
import Endpoints from '../../common/Endpoints';
import { GET, extractData, POST } from '../../../Consumer';
import { PREFERENCES_SUB_NAV_LIST } from '../../common/constants'
import Header from '../../common/Header';
import { ErrorAlert, SuccessAlert } from '../../common/Alert';

const SUCCESS_MESSAGE = 'Your courier preferences have been updated successfully.';
const ERROR_MESSAGE = 'There was a problem updating your courier preferences. Please try again.';

class Courier extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            existingPreferences: [],
            activeCouriers: [],
            formOptions: [],
            showError: false,
            showSuccess: false
        };

        this.toggleCourier = this.toggleCourier.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    async componentDidMount() {
        await Promise.all([this.fetchExistingPreferences(), this.fetchCouriers()]);
        this.buildOptions();
    }

    async fetchExistingPreferences() {
        return await GET(Endpoints.RETAILER.GET.COURIER_PARTNER_PREFERENCES)
            .then(response => {
                if (response.ok) return response.json();
            })
            .then(results => {
                const existingPreferences = Array.isArray(extractData(results)) ? extractData(results) : [];
                this.setState({ existingPreferences: existingPreferences });
            })
            .catch(error => console.log(error));
    }

    async fetchCouriers() {
        return await GET(Endpoints.COURIER.GET.ACTIVE)
            .then(response => {
                if (response.ok) return response.json();
            })
            .then(results => {
                const activeCouriers = Array.isArray(extractData(results)) ? extractData(results) : [];
                this.setState({ activeCouriers: activeCouriers });
            })
            .catch(error => console.log(error));
    }

    buildOptions() {
        const couriers = this.state.activeCouriers || [];
        const preferences = this.state.existingPreferences || [];
        const options = [];

        for (const i in couriers) {
            const pref = preferences.filter((p, x) => p.partnerCompanyID === couriers[i].id && p.partnerType === 'Courier');
            
            if (pref.length === 1) {
                options.push({ partnerCompanyID: pref[0].partnerCompanyID, name: couriers[i].name, supported: pref[0].supportedPartnership });
            } else {
                options.push({ partnerCompanyID: couriers[i].id, name: couriers[i].name, supported: false });
            }
        }

        this.setState({ formOptions: options, loading: false });
    }

    toggleCourier({ target }) {
        let options = this.state.formOptions;
        let idx = target.getAttribute('idx');

        options[idx].supported = !options[idx].supported;

        this.setState({ formOptions: options });
    }

    onSubmit(event) {
        event.preventDefault();
        this.setState({ loading: true });

        const formOptions = this.state.formOptions;
        POST(Endpoints.RETAILER.POST.COURIER_PARTNER_PREFERENCES, formOptions)
            .then(response => {
                if (response && response.ok) return response.json();
            })
            .then(results => {
                const existingPreferences = Array.isArray(extractData(results)) ? extractData(results) : [];
                this.setState({ existingPreferences: existingPreferences, loading: false, showError: results.error, showSuccess: !results.error });
            })
            .catch(error => console.log(error));
    }

    buildForm() {
        return (
            <Form onSubmit={this.onSubmit}>
                {this.state.formOptions.sort((a, b) => a.name > b.name ? 1 : -1).map((c, i) => {
                    return (
                        <div key={i}>
                            <p><strong>{c.name}</strong></p>
                            <FormGroup className="custom-control custom-checkbox mb-2">
                                <input id={c.partnerCompanyID} idx={i} className="custom-control-input" type="checkbox" onChange={this.toggleCourier} checked={c.supported} />
                                <FormLabel className="custom-control-label" htmlFor={c.partnerCompanyID}>Use this Courier?</FormLabel>
                            </FormGroup>
                        </div>);
                })}
                <Button variant="primary" className="float-right mt-3" type='submit'>Save</Button>
            </Form>
        );
    }

    render() {
        const { loading, showError, showSuccess, formOptions } = this.state;
        return loading ? (<LoadingBar />) : (
            <React.Fragment>
                <Header title="Preferences" subNavList={PREFERENCES_SUB_NAV_LIST} activeKey="Global" />
                <Container fluid>
                    <Row>
                        <Col sm={12} md={6}>
                            <h5 className="mb-3">Global Courier Preferences</h5>
                            {showError && <ErrorAlert errorMessage={ERROR_MESSAGE} />}
                            {showSuccess && <SuccessAlert successMessage={SUCCESS_MESSAGE} />}
                            <p>Be aware that opting out of using couriers may limit your options when processing orders.</p>
                            <p>This can result in orders failing to be processed, in this situation, you will be notified.</p>
                            <p><strong>These options override any other courier preferences.</strong></p>
                            <div className="form-input-description">
                                <p>Unchecking any of these options will completely remove them from consideration in all situations (regardless of any other preferences).</p>
                            </div>
                            {formOptions.length
                                ? this.buildForm()
                                : <ErrorAlert errorMessage="Your warehouse selection does not have any available courier services." /> 
                            }
                        </Col>
                    </Row>
                </Container>
            </React.Fragment>
        );
    }
}

export default Courier;