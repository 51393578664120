import React, { Fragment, useState, useEffect, useCallback } from 'react';
import Endpoints from '../../../common/Endpoints';
import Header from '../../../common/Header';
import LoadingBar from '../../../common/LoadingBar';
import NoResults from '../../../common/Tables/NoResults';
import Pagination from '../../../common/Tables/Pagination';
import { ErrorAlert } from '../../../common/Alert';
import { GET_ASYNC, extractData } from '../../../../Consumer';
import { Table, Container, Card, Form, Row, Col, Button } from 'react-bootstrap';
import { RETURNS_SUB_NAV_LIST } from '../../../common/constants'
import { Link } from "react-router-dom";
import { toLocalTimeString } from '../../../../Utilities';

const ERROR_MESSAGE = "Cannot retrieve returns";

const ReturnRow = ({ returnItem }) =>
    <tr>
        <td><Link to={`/retailer/returns/${returnItem.retailerReturnID}`}>{returnItem.orderID}</Link></td>
        <td>{returnItem.customerName}</td>
        <td>{returnItem.returnService}</td>
        <td><span className="text-status-green font-weight-bold">Processed</span></td>
        <td>{toLocalTimeString(returnItem.processedDate)}</td>
    </tr>

const ReturnProcessingTable = ({ returns }) =>
    <Table striped bordered size="sm" className={!returns.length ? "table-empty" : ""}>
        <thead>
            <tr>
                <th>Order Number</th>
                <th>Customer Name</th>
                <th>Return Service</th>
                <th>Status</th>
                <th>Processed by Warehouse</th>
            </tr>
        </thead>
        <tbody>
            {returns.length
                ? returns.map(r => <ReturnRow key={`${r.retailerReturnID}`} returnItem={r} />)
                : <NoResults colSpan="4" text="No returns to show." />
            }
        </tbody>
    </Table>

const ListReturns = () => {

    //#region State

    const [loading, setLoading] = useState(false);
    const [showError, setShowError] = useState(false);

    const [pageIndex, setPageIndex] = useState(1);
    const [pageCount, setPageCount] = useState(0);
    const [searchValue, setSearchValue] = useState("");
    const [searchTerm, setSearchTerm] = useState("");

    const [returns, setReturns] = useState(1);

    //#endregion

    //#region API

    useEffect(() => {
        (async () => {
            setLoading(true);
            try {

                const url = new URL(Endpoints.RETURNS.GET.PROCESSED_RETURNS);
                url.searchParams.append("search", searchTerm);
                url.searchParams.append("pageIndex", pageIndex)

                const response = await GET_ASYNC(url);

                if (response?.ok) {
                    const responseData = await response.json();
                    const { returns, pageCount } = extractData(responseData);
                    setReturns(returns);
                    setPageCount(pageCount);
                } else {
                    setShowError(true);
                }

            } catch (error) {
                console.log({ error });
                setShowError(true);
            } finally {
                setLoading(false);
            }
        })();
    }, [pageIndex, searchTerm]);

    //#endregion

    //#region Controls

    const handleSearchValueChange = (event) => {
        setSearchValue(event.target.value);
    };

    const handleSearch = (event) => {
        event.preventDefault();
        setSearchTerm(searchValue);
    };

    const onClear = (event) => {
        event.preventDefault();
        setSearchTerm("");
    };

    const onNext = () => {
        if (pageIndex < pageCount) setPageIndex((index) => index + 1);
    };

    const onPrevious = () => {
        if (pageIndex > 1) setPageIndex((index) => index - 1);
    };

    const onStart = () => setPageIndex(1);
    const onEnd = () => setPageIndex(pageCount);

    //#endregion

    return (
        loading
            ? <LoadingBar />
            : <Fragment>
                <Header title="Returns" subNavList={RETURNS_SUB_NAV_LIST} activeKey="Returns" />
                <Container fluid>
                    {showError
                        ? <ErrorAlert errorMessage={ERROR_MESSAGE} />
                        : <Fragment>
                            <Form onSubmit={handleSearch} className="mt-3 mb-4">
                                <Row>
                                    <Col>
                                        <Form.Control
                                            type="search"
                                            onChange={handleSearchValueChange}
                                            value={searchValue}
                                            placeholder="Search by order number"
                                        />
                                    </Col>
                                    <Col className="d-flex justify-content-end">
                                        <Button variant="link" onClick={onClear}>Clear filter</Button>
                                        <Button type="submit" variant="primary">Apply filter</Button>
                                    </Col>
                                </Row>
                            </Form>
                            <Card className="card-table">
                                <div className="table-responsive">
                                    <ReturnProcessingTable returns={returns} />
                                    <Pagination onNext={onNext} onPrevious={onPrevious} onStart={onStart} onEnd={onEnd} pageIndex={pageIndex} pageCount={pageCount} />
                                </div>
                            </Card>
                        </Fragment>
                    }
                </Container>
            </Fragment>
    );
}

export default ListReturns;
